import "./custom-plan.css";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import React from "react";

import StorageService from "../../../data/storage/storage-service";
import { useDispatch } from "react-redux";
import { Col, Figure, InputGroup, Modal, Nav, Row } from "react-bootstrap";
import map from "../../../assets/map.png";
import { faArrowRight, faBars, faCommentDots, faCrosshairs, faEnvelope, faMagnifyingGlass, faMap, faTag, faTimes } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from "../../../components/form-field-error/form-field-error";
import HomeProfessionalFollow from "../../home/components/home-professional-follow";
import HomeProfessionalFollowing from "../../home/components/home-professional-following";
import HomeProfessionalList from "../../home/components/home-professional-list";
import ProfessionLocationSearch from "../../home/components/profession-location-search";
import ProfessionTypeSearch from "../../home/components/profession-type-search";
import { PropertySearchAction } from "../../home/state/search-action";
import { Footer, Header } from "../../../layouts";
import building from "../../../assets/housemodel.png"
import buildup from "../../../assets/buildup.png"

import plot from "../../../assets/Plot.png"
import plotdimension from "../../../assets/newplotfacing.png"
import door from "../../../assets/newdoor.png"
import user from "../../../assets/User.png"
import location from "../../../assets/location.png"
import whatsapp from "../../../assets/Whatsapp.png"
import mail from "../../../assets/mail.png"
import notes from "../../../assets/Note.png"
import VerifiedIcon from '@mui/icons-material/Verified';



import bhk from "../../../assets/newbhk.png"
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import { PagePostTagFollowPayload, PagePostTagViewPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import TagSearch from "../../profession/tag-search/tag-search";
import LoginPageDialog from "../../login/login-page-dialog";
import { customPayload } from "../../../data/api/services/property/create-property-payload";
import SmsApiService from "../../../data/api/services/sms/sms-api-service";



const CustomPlan = (props: any) => {
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value);
    };
    let navigate: any = useNavigate();

    const [widthFeet, setWidthFeet] = useState('');
    const [widthInches, setWidthInches] = useState('');
    const [heightFeet, setHeightFeet] = useState('');
    const [heightInches, setHeightInches] = useState('');
    const [plotWidthFeet, setPlotWidthFeet] = useState('');
    const [plotWidthInches, setPlotWidthInches] = useState('');
    const [plotHeightFeet, setPlotHeightFeet] = useState('');
    const [name, setName] = useState('');
    const [otpVerified, setOtpVerified] = useState(false);

    const [whatsappNumber, setwhatsappNumber] = useState('');
    const [finalAmount, setfinalAmount] = useState('');

    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [error, setError] = useState('');
    const [isVerifyDisabled, setIsVerifyDisabled] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);

    const [generatedOtp, setGeneratedOtp] = useState("");

    const [termsConditionId, setTermsConditionId] = useState<number>(0);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '']);

    const [plotHeightInches, setPlotHeightInches] = useState('');
    const [approximateDimension, setApproximateDimension] = useState(false);
    const [agree, setAgree] = useState(false); // Initialize agree as false

    const [selectedHouseModel, setSelectedHouseModel] = useState('');
    const [selectedDoorFacing, setSelectedDoorFacing] = useState('');
    const [selectedPlotFacing, setSelectedPlotFacing] = useState('');
    const [selectedBhk, setSelectedBhk] = useState('');


    const [loading, setLoading] = useState(false);


    const [selectedPlanIds, setSelectedPlanIds] = useState([]);

    const [selectedtermId, setSelectedTermId] = useState([]);


    const [totalsquareAmount, setTotalSquareAmount] = useState(0);
    const [planSettingValidationErrorMsg, setPlanSettingValidationErrorMsg] = useState(null);
    const [planSettingData, setPlanSettingData] = useState([]);
    const [buildingWidthFeet, setBuildingWidthFeet] = useState('');

    const [planSettingIdStr, setPlanSettingIdStr] = useState("");
    const [HouseModelData, setHouseModelData] = useState([]);
    const [doorFacingData, setDoorFacingData] = useState([]);
    const [plotFacingData, setPlotFacingData] = useState([]);
    const [BhKData, setBhkData] = useState([]);
    const [termsData, setTermsData] = useState([]);
    const [enteredOtp, setEnteredOtp] = useState(''); // State to store user input OTP


    const [locationSearchLoading, setLocationSearchLoading] = useState(false);



    const [selectedLocationId, setSelectedLocationId] = useState('');


    const [selectedLocationName, setSelectedLocationName] = useState('');



    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const userId = StorageService.getUserId();
    const [termsCondition, setTermsCondition] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [seconds, setSeconds] = useState(0);

    const propertyApiService = new PropertyApiService();
    const smsService: SmsApiService =new SmsApiService

    const professionalPageApiService = new ProfessionalPageApiService();
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
    const [locationData, setLocationData] = useState([]);


    const [titleData, setTitleData] = useState([]);

    const imageArray = [1, 2, 3, 4];

    const [selectedLocation, setSelectedLocation] = useState([]);


    const handletermChange = (e) => {
        setTermsConditionId(e.target.checked);
    };
    const searchLocation = async (s: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(s);
        console.log(s);
        setLocationSearchLoading(false);
        setLocationData(data);
    };
    const handleVerifyClick = () => {
        setLoading(false);
        setIsOtpSent(true); // Start countdown for OTP resend
        setShowOtpInput(true);
        setIsButtonVisible(false);
        setSeconds(10); // Set countdown duration to 10 seconds
        generateOtp();
      }
    const onLocationSelected = (t: any) => {
        if (t.length > 0) {
            console.log(t[0]);
            setSelectedLocation(t);
            setSelectedLocationId(t[0].id);
            setSelectedLocationName(t[0].name);
        }
    };
    const handlebuildingInchesOnChange = (bi: any) => {
        setWidthInches(bi);
    };
    const handlebuildingHeightInchesOnChange = (hi: any) => {
        setHeightInches(hi);


    };
    const generateOtp = async () => {
        try {
            let data = await smsService.generateOtp(whatsappNumber);
            setGeneratedOtp(data.content);
            console.log(data.content);
            if (data.status == "Success") {
                toast.success("Otp send ...", { containerId: 'TR' });

            } else {
                setLoading(false);
                toast.error("Try Again...", { containerId: 'TR' });
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error(error.response.data.message, { containerId: 'TR' });
        }
    };
    const handleOtpChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value.slice(0, 1); // Limit input to 1 character
        setOtp(newOtp);

        // Automatically focus on the next input
        if (e.target.value && index < 3) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
    };

    useEffect(() => {
        let countdown;
        if (isOtpSent && seconds > 0) {
          countdown = setInterval(() => setSeconds((prev) => prev - 1), 1000);
        } else if (seconds === 0) {
          setIsOtpSent(false); // Reset to allow "Resend OTP" to be clickable and red
          clearInterval(countdown);
        }
        return () => clearInterval(countdown);
      }, [isOtpSent, seconds]);

      const handleResetClick = () => {
        setIsOtpSent(true);
        setSeconds(10); // Restart countdown for Resend OTP
        generateOtp();
      };
 
    const handleConfirmClick = () => {
        if (enteredOtp === generatedOtp) {
            toast.success("OTP verified successfully!", { containerId: 'TR' });
            setOtpVerified(true); 

            setShowOtpInput(false);

        } else {
            toast.error("Incorrect OTP, please try again.", { containerId: 'TR' });
        }
    };

    const handleplotwidthfeetOnChange = (pf: any) => {
        setPlotWidthFeet(pf);
    };

    const handleplotwidthinchesOnChange = (pi: any) => {
        setPlotWidthInches(pi);


    };


    const handleplotHeightFeetOnChange = (ph: any) => {
        setPlotHeightFeet(ph);


    };
    const handlenameOnChange = (na: any) => {
        setName(na);


    };

    const handlewhatsappNumberOnChange = (wn: any) => {
        setwhatsappNumber(wn);


    };
    const handleemailOnChange = (em: any) => {
        setEmail(em);
    };

    const handlenoteOnChange = (no: any) => {
        setNote(no);


    };

    const handleplotHeightInchesOnChange = (phi: any) => {
        setPlotHeightInches(phi);


    };


    const width = parseFloat(widthFeet) || 0;
    const height = parseFloat(heightFeet) || 0;
    const totalArea = width * height;
    const handleHouseModelChange = (event) => {
        setSelectedHouseModel(event.target.value);
    };
    const handleDoorFacingChange = (event) => {
        setSelectedDoorFacing(event.target.value);
    };
    const handlePlotFacingChange = (event) => {
        setSelectedPlotFacing(event.target.value);
    };
    const handleBhkChange = (event) => {
        setSelectedBhk(event.target.value);
    };

    const getTemplateData = async () => {
        let data = await professionalPageApiService.getCustomPlanTemplateData();
        console.log(data);
        setPlanSettingData(data.customerPlanSettingData);
        setHouseModelData(data.houseModelData);
        setDoorFacingData(data.doorFacingData);
        setPlotFacingData(data.plotFacingData);
        setBhkData(data.bhkTypeData);
        setTermsData(data.termsConditionData);


        if (data.termsConditionData.length > 0) {
            setTermsCondition(data.termsConditionData[0].termsCondition);
            setTermsConditionId(data.termsConditionData[0].id);

        }
    };
    const handleAgreeChange = (e) => {
        const isChecked = e.target.checked;

      
        if (!widthFeet) {
            toast.error('Please enter the building width in feet', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!heightFeet) {
            toast.error('Please enter the building height in feet', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!selectedPlanIds || selectedPlanIds.length === 0) {
            toast.error('Please select at least one plan', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!selectedHouseModel) {
            toast.error('Please select house model', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!selectedBhk) {
            toast.error('Please select BHK type', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!selectedDoorFacing) {
            toast.error('Please select door facing', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!selectedPlotFacing) {
            toast.error('Please select plot facing', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!name) {
            toast.error('Please enter name', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        if (!whatsappNumber) {
            toast.error('Please enter WhatsApp number', { containerId: 'TR' });
            setAgree(false);
            return;
        }
        if (!enteredOtp) {
            toast.error('Please verify the otp ', { containerId: 'TR' });
            setAgree(false);
            return;
        }

        setAgree(isChecked);
    };



    const handleTermsConditionChange = (id: number) => {
        setTermsConditionId(prevId => (prevId === id ? 0 : id));
    };
    const createPlan = async () => {
        try {





            let payload: customPayload = {
                buildingWidthFeet: widthFeet,
                buildingWidthInches: widthInches,
                buildingHeightFeet: heightFeet,
                buildingHeightInches: heightInches,
                plotWidthFeet: plotWidthFeet,
                plotWidthInches: plotWidthInches,
                plotHeightFeet: plotHeightFeet,
                plotHeightInches: plotWidthInches,
                approximateDimension: approximateDimension ? 'yes' : '',
                houseModel: selectedHouseModel,
                doorFacing: selectedDoorFacing,
                plotFacing: selectedPlotFacing,
                bhk: selectedBhk,
                name: name,
                location: selectedLocationId,
                whatsappNumber: whatsappNumber,
                email: email,
                note: note,
                paymentStatus: 'NotPaid',
                termsCondition: 'Agree',
                totalAmount: finalAmount,
                planId: selectedPlanIds.map(id => ({ id })),



            };

            await propertyApiService.createCustomPlan(payload);
            toast.success('Your request was successfully submitted', { containerId: 'TR' });
            navigate('/plans');

        } catch (error: any) {
            console.log("Plan Error", error);
            toast.error(error.message, { containerId: 'TR' });
        }
    };



    const handleCheckboxChange = (id: string, amount: number) => {

        let updatedPlanIds: string[];
        let updatedAmount: number;

        if (selectedPlanIds.includes(id)) {
            updatedPlanIds = selectedPlanIds.filter((selectedId) => selectedId !== id);
            updatedAmount = totalsquareAmount - amount;
        } else {

            updatedPlanIds = [...selectedPlanIds, id];
            updatedAmount = totalsquareAmount + amount;
        }

        setSelectedPlanIds(updatedPlanIds);
        setTotalSquareAmount(updatedAmount);
    };


    useEffect(() => {
        const calculatedAmount = totalsquareAmount * totalArea;
        setfinalAmount(calculatedAmount.toString()); // Convert to string for payload
    }, [totalsquareAmount, totalArea]);

    const handleInputChange = (e) => {
    };


    const handleWidthChange = (e) => {
        setWidthFeet(e.target.value);
    };

    const handleHeightChange = (e) => {
        setHeightFeet(e.target.value);
    };

    const handlePlanSettingChange = (btId: any) => {
        setPlanSettingIdStr(btId);
    };





    const getProfessionalPagePostDataList = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setProfessionalPagePosts(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };








    useEffect(() => {
        getTemplateData();

    }, []);

    const renderMenuItemChildren = (option, props, index) => {

        return (
            <div key={index}>
                <span style={{ color: 'black' }}>{option.name}</span>
                {/* {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span> */}

            </div>
        );
    };
















    return (
        <>
            <section className="d-flex justify-content-center">
                <Col md={6}>
                    <div className="d-flex justify-content-center mt-4">
                        <label style={{ fontSize: '20px', color: 'black' }}>
                            Need Custom Plan?
                        </label>
                    </div>
                    <Col md={12} className=" mt-2 d-flex justify-content-center" style={{
                        backgroundColor: '#c7c5c53b',
                        borderRadius: '23px'
                    }} >

                        <div className="col-lg-8">
                            <div className="d-flex justify-content-center mt-3">
                                <label style={{ fontSize: '19px', color: 'grey' }}>
                                    Floor Plan Drawing Request Form
                                </label>
                            </div>

                            <div className="d-flex justify-content-center mt-3">
                                <label style={{ fontSize: '19px', color: 'black' }}>
                                    Building Dimension
                                </label>
                            </div>

                            <div className="d-flex flex-column flex-lg-row align-items-center mt-3" style={{ gap: '10%' }}>


                                <img src={buildup} alt="building" style={{ width: '50px', height: '50px' }} />


                                <div >
                                    <span style={{ fontSize: '18px', color: 'grey',paddingLeft:'15px' }}>Width</span>


                                    <div style={{ display: 'flex', justifyContent: 'space-between',paddingLeft:'10px' }}>
                                        <div style={{ marginRight: '10px' }}>
                                            <input
                                                type="number"
                                                name="widthFeet"
                                                placeholder="Feet"
                                                value={widthFeet}
                                                onChange={handleWidthChange}
                                                style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px' }}
                                            />        </div>
                                        <div >
                                            <input type="number" placeholder="Inches" value={widthInches}
                                                onChange={e => { handlebuildingInchesOnChange(e.target.value) }} style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px',marginLeft:'-7px'  }} />
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <span style={{ fontSize: '18px', color: 'grey',paddingLeft:'15px' }}>Height</span>


                                    <div style={{ display: 'flex', justifyContent: 'space-between',paddingLeft:'10px' }}>
                                        <div style={{ marginRight: '10px' }}>
                                            <input
                                                type="number"
                                                name="heightFeet"
                                                placeholder="Feet"
                                                value={heightFeet}
                                                onChange={handleHeightChange}
                                                style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px' }}
                                            />        </div>
                                        <div >
                                            <input type="number" placeholder="Inches" value={heightInches}
                                                onChange={e => { handlebuildingHeightInchesOnChange(e.target.value) }} style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px',marginLeft:'-7px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-3">
                                <label style={{ fontSize: '19px', color: 'black' }}>
                                    Plot Dimension
                                </label>
                            </div>
                            <div className="d-flex flex-column flex-lg-row align-items-center" style={{ gap: '10%' }}>

                                <img src={plot} alt="plot" style={{ width: '50px', height: '50px' }} />


                                <div >
                                    <span style={{ fontSize: '18px', color: 'grey',marginLeft:'15px' }}>Width</span>


                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ marginRight: '10px',marginLeft:'15px'  }}>
                                            <input
                                                type="number"
                                                name="widthFeet"
                                                placeholder="Feet"
                                                value={plotWidthFeet}
                                                onChange={e => { handleplotwidthfeetOnChange(e.target.value) }}

                                                style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px' }}
                                            />        </div>
                                        <div >
                                            <input type="number" placeholder="Inches" value={plotWidthInches}
                                                onChange={e => { handleplotwidthinchesOnChange(e.target.value) }} style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px' ,marginLeft:'-7px' }} />
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <span style={{ fontSize: '18px', color: 'grey' ,marginLeft:'15px' }}>Height</span>


                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ marginRight: '10px',marginLeft:'15px'  }}>
                                            <input
                                                type="number"
                                                name="heightFeet"
                                                placeholder="Feet"
                                                value={plotHeightFeet}
                                                onChange={e => { handleplotHeightFeetOnChange(e.target.value) }}
                                                style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px' }}
                                            />        </div>
                                        <div >
                                            <input type="number" placeholder="Inches" value={plotHeightInches}
                                                onChange={e => { handleplotHeightInchesOnChange(e.target.value) }} style={{ width: '100%', padding: '5px', border: '2px solid #808080b0', borderRadius: '13px',marginLeft:'-7px'  }} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{  textAlign: 'end',paddingRight:'21px' , marginTop: '20px' }}>
                                <input
                                    type="checkbox"
                                    id="approCheckbox"
                                    style={{ marginRight: '5px' }}
                                    checked={approximateDimension}
                                    onChange={() => setApproximateDimension(!approximateDimension)}
                                />
                                <label htmlFor="approCheckbox" style={{ fontSize: '15px', color: 'black' }}>Approx Dimension</label>
                            </div>
                         
                            <div style={{ textAlign: 'center', color: 'rgb(73, 195, 235)', fontSize: '20px' }}>
                                Total: {totalArea} sqft</div>
                            <div>

                                <div className="d-flex justify-content-center mt-2">
                                    <div
                                        className="custom_plan-container"
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            paddingLeft:'26px'
                                        }}
                                    >
                                        {planSettingData && planSettingData.map((psData) => (
                                            <label
                                                key={psData.id}
                                                className="checkbox-label"
                                                style={{
                                                    flex: '0 0 50%',
                                                    marginBottom: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    position: 'relative',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedPlanIds.includes(psData.id)}
                                                    onChange={() => handleCheckboxChange(psData.id, psData.amount)}
                                                    style={{
                                                        display: 'none',
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        border: '2px solid #ccc',
                                                        borderRadius: '4px',
                                                        marginRight: '5px',
                                                        position: 'relative',
                                                        backgroundColor: selectedPlanIds.includes(psData.id) ? 'white' : 'transparent',
                                                        transition: 'background-color 0.3s',
                                                    }}
                                                >
                                                    {selectedPlanIds.includes(psData.id) && (
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                left: '5px',
                                                                top: '2px',
                                                                width: '5px',
                                                                height: '10px',
                                                                border: 'solid orange',
                                                                borderWidth: '0 2px 2px 0',
                                                                transform: 'rotate(45deg)',
                                                            }}
                                                        ></span>
                                                    )}
                                                </span>
                                                <span style={{ color: 'grey', fontSize: '14px' }}>
                                                    {psData.planSetting}
                                                </span>
                                            </label>
                                        ))}
                                    </div>

                                    <FormFieldError errorMessage={planSettingValidationErrorMsg} />
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row">
                               

                                <div className="d-flex col-lg-6 align-items-center pe-3">
                                    <img
                                        src={bhk}
                                        alt="bhk"
                                        style={{ width: '80px', height: '80px', objectFit: 'contain' }}
                                    />
                                    <div style={{ flexGrow: 1}}>
                                        <select
                                            value={selectedBhk}
                                            onChange={handleBhkChange}
                                            style={{
                                                padding: '5px',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                                backgroundColor: '#f0f0f0',
                                                border: '1px solid #ccc',
                                                outline: 'none',
                                                width:'100%'
                                            }}
                                        >
                                            <option value="">BHK Type</option>
                                            {BhKData.map(bhk => (
                                                <option key={bhk.id} value={bhk.id}>
                                                    {bhk.bhkType}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>





                                <div className="d-flex col-lg-6 align-items-center pe-3">
                                    <img
                                        src={building}
                                        alt="Buildup"
                                        style={{ width: '80px', height: '80px', objectFit: 'contain' }}
                                    />
                                    <div style={{ flexGrow: 1}}>
                                        <select
                                            value={selectedHouseModel}
                                            onChange={handleHouseModelChange}
                                            style={{
                                                padding: '5px',
                                                fontSize: '16px',
                                                borderRadius: '5px',
                                                backgroundColor: '#f0f0f0',
                                                border: '1px solid #ccc',
                                                outline: 'none',
                                                width:'100%'
                                            }}
                                        >
                                            <option value="">House Model</option>
                                            {HouseModelData.map(houseModel => (
                                                <option key={houseModel.id} value={houseModel.id}>
                                                    {houseModel.houseModel}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>






                            </div>

                     <div className="d-flex flex-column flex-lg-row">
                               

                               <div className="d-flex col-lg-6 align-items-center pe-3">
                                   <img
                                       src={door}
                                       alt="door"
                                       style={{ width: '80px', height: '80px', objectFit: 'contain' }}
                                   />
                                   <div style={{ flexGrow: 1}}>
                                       <select
                                           value={selectedDoorFacing}
                                           onChange={handleDoorFacingChange}
                                           style={{
                                               padding: '5px',
                                               fontSize: '16px',
                                               borderRadius: '5px',
                                               backgroundColor: '#f0f0f0',
                                               border: '1px solid #ccc',
                                               outline: 'none',
                                               width:'100%'
                                           }}
                                       >
                                           <option value="">Door Facing</option>
                                           {doorFacingData.map(door => (
                                               <option key={door.id} value={door.id}>
                                                   {door.label}
                                               </option>
                                           ))}
                                       </select>
                                   </div>
                               </div>





                               <div className="d-flex col-lg-6 align-items-center pe-3">
                                   <img
                                       src={plotdimension}
                                       alt="plot"
                                       style={{ width: '80px', height: '80px', objectFit: 'contain' }}
                                   />
                                   <div style={{ flexGrow: 1}}>
                                       <select
                                           value={selectedPlotFacing}
                                           onChange={handlePlotFacingChange}
                                           style={{
                                               padding: '5px',
                                               fontSize: '16px',
                                               borderRadius: '5px',
                                               backgroundColor: '#f0f0f0',
                                               border: '1px solid #ccc',
                                               outline: 'none',
                                               width:'100%'
                                           }}
                                       >
                                           <option value="">Plot Facing</option>
                                           {plotFacingData.map(plotFacing => (
                                               <option key={plotFacing.id} value={plotFacing.id}>
                                                   {plotFacing.label}
                                               </option>
                                           ))}
                                       </select>
                                   </div>
                               </div>






                           </div>
     
                           <div className="container mt-3">
      {/* First Row: Name */}
      <div className="row justify-content-start align-items-center mb-3">
        <div className="col-6 d-flex align-items-center mb-2 mb-md-0">
          <img
            src={user}
            alt="user"
            style={{ width: '80px', height: '50px', objectFit: 'contain', marginRight: '10px' }}
          />
          <label className="hide-label" style={{ color: 'black', fontSize: '15px', whiteSpace: 'nowrap' }}>
            Your Name <span style={{ color: 'red' }}>*</span>
          </label>
        </div>
        <div className="col-6 ">
          <input
            type="text"
            value={name}
            placeholder="Your name"
            onChange={e => handlenameOnChange(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '5px',
              backgroundColor: '#f0f0f0',
              border: '1px solid #ccc',
              outline: 'none',
              width: '100%',
            }}
          />
        </div>
      </div>

      <div className="row justify-content-start align-items-center">
        <div className="col-6 d-flex align-items-center mb-2 mb-md-0">
          <img
            src={location}
            alt="location"
            style={{ width: '80px', height: '80px', objectFit: 'contain', marginRight: '10px' }}
          />
          <label className="hide-label" style={{ color: 'black', fontSize: '15px', whiteSpace: 'nowrap' }}>Location</label>
        </div>
        <div className="col-6">
          <AsyncTypeahead
            id="tag-search"
            labelKey="name"
            onSearch={searchLocation}
            options={locationData}
            onChange={onLocationSelected}
            useCache={false}
            placeholder="Search location"
            defaultSelected={selectedLocation}
            isLoading={locationSearchLoading}
            renderMenuItemChildren={renderMenuItemChildren}
            inputProps={{
              style: {
                width: '100%',
                padding: '8px',
                borderRadius: '5px',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ccc',
                outline: 'none',
              },
            }}
          />
        </div>
      </div>

      
      <div className="row justify-content-start align-items-center ">
  <div className="col-6 d-flex align-items-center mb-2 mb-md-0">
    <img
      src={whatsapp}
      alt="whatsapp"
      style={{ width: '80px', height: '80px', objectFit: 'contain', marginRight: '10px' }}
    />
    <label className="hide-label" style={{ color: 'black', fontSize: '15px' }}>
      WhatsApp Number <span style={{ color: 'red' }}>*</span>
    </label>
  </div>
  <div className="col-6 d-flex align-items-center">
    <input
      type="number"
      value={whatsappNumber}
      placeholder="Whatsapp number"
      onChange={e => handlewhatsappNumberOnChange(e.target.value)}
      style={{
        width: '100%',
        padding: '8px',
        borderRadius: '5px',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        outline: 'none',
      }}
    />
 {otpVerified && (
  <VerifiedIcon style={{ color: '#0d6efd', marginLeft: '10px', fontSize: '30px' }} />
)}

  </div>
</div>

      {whatsappNumber.length === 10 && isButtonVisible &&(
  <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mt-3">
    <div className="d-flex col-lg-6 align-items-center justify-content-center pe-3">
      <button className="p-2"
        onClick={handleVerifyClick}
        disabled={isVerifyDisabled}
        style={{
          backgroundColor: isVerifyDisabled ? '#ccc' : 'rgb(74, 136, 157)',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
          color: 'white',
        }}
      >
        Verify 
      </button>
    </div>
  </div>
)}

{showOtpInput && (
  <div
    style={{
      marginTop: '20px',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      margin: '0 auto',
    }}
  >
    <p style={{ fontSize: '16px', marginBottom: '10px', textAlign: 'center' }}>Enter your OTP</p>
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
      <input
        type="text"
        value={enteredOtp}
        onChange={(e) => setEnteredOtp(e.target.value)}
        placeholder="Enter OTP"
        style={{
          width: '150px',
          padding: '10px',
          fontSize: '16px',
          textAlign: 'center',
          border: '1px solid #ccc',
          borderRadius: '5px',
          outline: 'none',
          marginBottom: '20px',
        }}
      />
    </div>
    
    <div className="text-center my-3">
    <span className="d-block mobile-text">
        Not received an OTP?{' '}
        <a
          href="#"
          className={isOtpSent ? 'text-muted' : 'text-danger'} // Red when clickable, gray during countdown
          onClick={(e) => {
            e.preventDefault();
            if (!isOtpSent) handleResetClick();
          }}
          style={{
            pointerEvents: isOtpSent ? 'none' : 'auto', // Disable clicks during countdown
            opacity: isOtpSent ? 0.5 : 1, // Reduce opacity during countdown
          }}
        >
          Resend OTP{isOtpSent && ` (${seconds}s)`}
        </a>
      </span>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
        <button
          className="font-weight-bold rounded btn btn-danger mt-2"
          onClick={handleConfirmClick}
          style={{
            backgroundColor: '#4caf50',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
        >
          Confirm
        </button>
       
      </div>
    </div>
  </div>
)}


      <div className="row justify-content-start align-items-center">
        <div className="col-6 d-flex align-items-center mb-2 mb-md-0">
          <img
            src={mail}
            alt="mail"
            style={{ width: '80px', height: '80px', objectFit: 'contain', marginRight: '10px' }}
          />
          <label className="hide-label" style={{ color: 'black', fontSize: '15px' }}>Email</label>
        </div>
        <div className="col-6">
          <input
            type="text"
            value={email}
            placeholder="Email"
            onChange={e => handleemailOnChange(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '5px',
              backgroundColor: '#f0f0f0',
              border: '1px solid #ccc',
              outline: 'none',
            }}
          />
        </div>
      </div>

      <div className="row justify-content-start align-items-center">
        <div className="col-6 d-flex align-items-center mb-2 mb-md-0">
          <img
            src={notes}
            alt="notes"
            style={{ width: '80px', height: '80px', objectFit: 'contain', marginRight: '10px' }}
          />
          <label className="hide-label" style={{ color: 'black', fontSize: '15px' }}>Note</label>
        </div>
        <div className="col-6">
          <input
            type="text"
            value={note}
            placeholder="Note"
            onChange={e => handlenoteOnChange(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '5px',
              backgroundColor: '#f0f0f0',
              border: '1px solid #ccc',
              outline: 'none',
            }}
          />
        </div>
      </div>
    </div>



                     
                          
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <label style={{
                                    color: 'rgb(73, 195, 235)',
                                    fontSize: '20px'
                                }}>
                                    Terms & Conditions
                                </label>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                                <label style={{
                                    textAlign:'center',
                                    fontSize: '17px',
                                    color: 'black',
                                    whiteSpace: 'normal',
                                    maxHeight: '3rem',
                                    overflowY: 'auto',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2, /* Show only two lines initially */
                                    WebkitBoxOrient: 'vertical',
                                    overflowX: 'hidden',
                                    fontWeight: '400',
                                    paddingLeft:'20px'
                                }} className="switch-account-scrollbar">
                                    {termsCondition}
                                </label>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px',paddingLeft:'22px' }}>
                                <input
                                    type="checkbox"
                                    id="agreeCheckbox"
                                    style={{ marginRight: '10px' }}
                                    checked={agree}
                                    onClick={handleAgreeChange} 
                                />
                                <label htmlFor="agreeCheckbox" style={{ fontSize: '15px', color: 'black' ,whiteSpace:'normal'}}>
                                    I have read and agree to the terms and conditions
                                </label>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div
                                    className="custom_plan-container"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >

                                    <div style={{ flex: '1', textAlign: 'center' }}>
                                        <span style={{ fontSize: '16px', color: 'black' }}>
                                            Rs: {finalAmount}/-
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '16px', color: 'green' }}>
                                            Note: {totalsquareAmount}Rs per sqft
                                        </span>
                                    </div>







                                </div>
                            </div>
                           


                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <button
                                        style={{
                                            margin: '10px',
                                            padding: '10px 20px',
                                            backgroundColor: '#FFA07A',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={createPlan}
                                        disabled={!agree} // Disable if agree is false
                                    >
                                        Pay Later
                                    </button>

                                    <button
                                        style={{
                                            margin: '10px',
                                            padding: '10px 20px',
                                            backgroundColor: '#90EE90',
                                            border: 'none',
                                            borderRadius: '5px',
                                            cursor: 'pointer',

                                        }}
                                        // onClick={createPlan}
                                        disabled={!agree}

                                    >
                                        Pay Now
                                    </button>
                                </div>

                            </div>
                            <div style={{ textAlign: 'end',paddingRight:'21px' }}>
                                <FeedbackOutlinedIcon />
                                <span> Send Feedback</span>
                            </div>


                        </div>




                    </Col>






                </Col>
            </section>
        </>
    );






};
export default CustomPlan;