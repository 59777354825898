import HttpClientWrapper from "../../http-client-wrapper";


class PlanStatusApiService {
    
    [x: string]: any;

    private httpClientWrapper: HttpClientWrapper;

    constructor() {
        this.httpClientWrapper = new HttpClientWrapper();
    }


    checkPlanStatusByMobileNumber = async(mobileNo:any) =>{
        try{
            let data:any = await this.httpClientWrapper.get('/v1/customPlans/customPlan/'+ mobileNo);

            return data;
        } catch (error) {
            throw error;
        }

    }

}
export default PlanStatusApiService;